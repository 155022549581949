/* eslint
    prettier/prettier: "off",
    func-names: "off",
    eqeqeq: "off",
    no-else-return: "off",
    no-use-before-define: "off",
    block-scoped-var: "off",
    max-nested-callbacks: "off",
    no-shadow: "off",
    no-unused-vars: "off",
    guard-for-in: "off",
    no-undef: "off",
    prefer-destructuring: "off",
    no-redeclare: "off",
    valid-jsdoc: "off",
    operator-assignment: "off",
    no-restricted-syntax: "off",
    no-lonely-if: "off",
    object-shorthand: "off",
    camelcase: "off",
*/
(function($) {
  const { MONTHLY_FREQUENCY, ANNUAL_FREQUENCY, DONATION_RECURRING_QUERY, PAYMENT_TYPES_QUERY, DONATION_TYPE_QUERY, isOneTimeDonationRadioButtonSelected, isMonthlyDonationRadioButtonSelected, isAnnualDonationRadioButtonSelected } = require('./utils/donation-form-helper.js');

  Drupal.behaviors.DonationFormMultistepJS = {
    attach: function (context, settings) {
      // Set global step progress
      var currentStep = 0;

      /**
       * Layout changes
       */

       // Selecting a different country in the country dropdown kicks off an ajax call
       // and Drupal re-runs all of the JS behaviors including all the code contained in this one.
       // Make sure code that should only run once does so
      $('.field-type-text-with-summary').prepend($('.page-title'));
      $('.field-name-field-aclu-summary .field-item')
        .addClass('body-mobile')
        .appendTo($('.field-name-body > .field-items'))
        .prev()
        .addClass('body-nonmobile');

      // For manipulating donation form processing fee HTML
      $('#webform-component-payment-information--processing-fee', context).once('manipulate-processing-fee-field',
        function() {
          $('[for="edit-submitted-payment-information-processing-fee"]').remove();
          $('.form-item-submitted-payment-information-payment-fields-credit-card-cvv').after($(this));

          // Add change handler for updating donation button amount when fee checkbox changes
          $('#edit-submitted-payment-information-processing-fee-1').on('change', function() {
            updateButtonText(getDonationAmount(), getDonationType());
          });
        }
      );

      // Move donation landing info from form component into pagewrapper placeholder
      $('.node-type-donation-form #other-ways-to-give').once('move-other-ways-to-give-customize-footer', function() {
        if ($('#webform-component-other-ways-to-give').length > 0 && $('#webform-component-other-ways-to-give').html().trim().length > 0){
          $('.donation-landing-section.row1 #other-ways-to-give').append($('#webform-component-other-ways-to-give'));
          $('.footer-wrapper').addClass('hide-border');
        } else {
          $('.donation-landing-section.row1').parent('aside').addClass('empty-section');
          if($('#webform-component-other-donation-info').length < 1 || $('#webform-component-other-donation-info').html().trim().length < 1){
            $('.multistep-donation.default-form-wrapper.multistep-donation-landing .webform-client-form').css('position', 'relative');
          }
        }

      });
      $('#other-donation-info').once('move-other-donation-info', function() {
        $('.donation-landing-section.row2 #other-donation-info').append($('#webform-component-other-donation-info'));
      });
      $('.page-node-done .donation-landing-section.row1').once('move-ready-to-do-more-confirmation', function() {
        $('.donation-landing-section.row1 .container .left-content').append($('#ready-to-do-more-confirmation'));
        if($('#ready-to-do-more-confirmation').length === 0){
          $('.donation-landing-section.row1').parent('aside').addClass('empty-section');
        } else {
          if($('#ready-to-do-more-confirmation').hasClass('has-background-white')) {
            $('.donation-landing-section.row1').parent('aside').addClass('has-background-white');
            $('.donation-landing-section.row1').addClass('has-background-white');
            if($('#ready-to-do-more-confirmation').hasClass('has-background-yellow')) {
              $('.donation-landing-section.row1').removeClass('has-background-yellow');
            }
          } else {
            $('.donation-landing-section.row1').parent('aside').addClass('has-background-yellow');
          }
        }
      });
      $('.donation-landing-section.row2').once('move-questions-confirmation', function() {
        $('.donation-landing-section.row2 .container .left-content').append($('#questions-confirmation'));
        $('.donation-landing-section.row2 .container').addClass('has-section-padding');
      });

      // Move secondary advocacy social share form component into pagewrapper placeholder
      $('#advocacy-social-share-block').once('move-advocacy-social-share-block', function() {
        $('#advocacy-social-share-block .share-content').append($('#webform-component-advocacy-social-share-block'));
      });

      $('.advocacy-donation-form .alert.status').once('move-alert-status-petition-confirmation-message', function() {
        $('.advocacy-donation-form .message-wrapper .alert.status').appendTo('.petition-confirmation-message');
      });

      // The c3 or c4 donation disclaimer form component exists, attach the form component to the end of the body text
      var disclaimerObj = null;
      if ($('#webform-component-c4-disclaimer').length) {
        disclaimerObj = $('#webform-component-c4-disclaimer');
      } else if ($('#webform-component-c3-disclaimer').length) {
        disclaimerObj = $('#webform-component-c3-disclaimer');
      }
      if (disclaimerObj !== null) {
        disclaimerObj.once().appendTo($('.field-name-body'));
      }

      $('form.webform-client-form', context).once('initialize-breadcrumb', function() {
        $(this).prepend($('.donation-steps'));
        // Add breadcrumb elements for screen reader accessibility
        $('.donation-steps .step-wrapper div.step').wrapAll('<div class="breadcrumb-nav" aria-hidden="true"><ol></ol></div>');
        $('.donation-steps .step-wrapper div:not(.breadcrumb-nav)').wrap('<li></li>');
        // Add note for any Voice Over screen readers that have trouble navigating the form - accessibility
        // Temp fix until we complete accessibility updates
        $('.donation-steps').prepend('<div id="help-message" class="element-invisible element-focusable">If you encounter a technical issue completing this form, <a href="https://www.aclu.org/donate-aclu">select this link to find a single page version of this form</a> or <a href="mailto:membership@aclu.org">please contact us at membership@aclu.org</a></div>');
      });

      /**
       * Definitions.
       */
      // Step fieldset class
      var stepFieldsetClass = '.fieldset-step';

      // Amount display message.
      var reminderText = '[type] donation';

      // Donation amount fields.
      var $otherAmountFields = $('#edit-submitted-donation-other-amount, #edit-submitted-donation-recurring-other-amount');
      var $oneTimeAskAmounts = $('#webform-component-donation--amount');
      var $monthlyAskAmounts = $('#webform-component-donation--recurring-amount');
      var $annualAskAmounts = $('.form-item-frequencies-sb-fs-annually-amount');


      // Fieldsets.
      var $donationFields = $('#webform-component-donation');
      var $paymentFields = $('#webform-component-payment-information');
      var $contactFields = $('#webform-component-donor-information');
      var $billingFields = $('#webform-component-billing-information');

      $('form.webform-client-form', context).once('wrap-step-fieldsets', function() {
        $donationFields.addClass('fieldset-step');
        $paymentFields.addClass('fieldset-step');
        $contactFields.addClass('fieldset-step');
        $billingFields.addClass('fieldset-step');
      });

      // Strip '.00' from specific dollar amounts
      $("span.trim-zero-cents", context).each(function trimCents(index, value) {
        var fullValue = $(value).html();
        var trimmedValue = fullValue.replace(/\.00$/,'');
        $(value).html(trimmedValue);
      });

      function isDonationLandingForm() {
        return $('.multistep-donation-landing').length > 0;
      }

      function isSwitcherForm() {
        return (
          $(DONATION_TYPE_QUERY).length > 0
        );
      }

      function reInitializeApplePay() {
        // We need to click the applepay radio button when we return to step 1 so
        // that when the user clicks the 'Donate with Apple Pay' button (which is
        // actually the form submit button) all applepay setup is already done (nonces, etc.).
        if ($('.applepay-available').length) {
          var applepayPaymentMethodRadio = $("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'applepay' });
          applepayPaymentMethodRadio.trigger('click');
        }
      }

      // Return the current donation amount.
      function getDonationAmount() {

      if ($(DONATION_TYPE_QUERY).length) {
        // Monthly, Annual or One-Time amount.
        var selectedAskValue = null;
        if ( isOneTimeDonationRadioButtonSelected() ) {
          selectedAskValue =  $oneTimeAskAmounts.find('.form-type-radio input:checked').val();
        } else if ( isAnnualDonationRadioButtonSelected() ) {
          selectedAskValue = $annualAskAmounts.find('.form-type-radio input:checked').val();
        } else if ( isMonthlyDonationRadioButtonSelected() ) {
          selectedAskValue = $monthlyAskAmounts.find('.form-type-radio input:checked').val();
        }
      } else {
        // Non-switcher forms have the same donation amounts as a one-time only form
        var selectedAskValue = $oneTimeAskAmounts.find('.form-type-radio input:checked').val();
      }

        // Handle 'other' option value.
        selectedAskValue = selectedAskValue === 'other' ? 0 : selectedAskValue;

        // Set selected amount, or 0.
        var selectAmount = selectedAskValue || 0;

        // Retrieve Other amount, if any.
        var otherAmount = 0;
        $otherAmountFields.each(function () {
          if ($(this).val()) {
            otherAmount = $(this).val();
            return false;
          }
        });

        // Determine donation amount.
        return selectAmount > otherAmount ? Number(selectAmount) : Number(otherAmount);
      }

      // Return the current donation type: one-time, monthly, or annual.
      function getDonationType() {
        var frequency = '';
        const annualCheckbox = $('#edit-submitted-donation-annual-opt-in-1')[0]
        const donationTypeRadio = $('#edit-submitted-donation-recurs-monthly input[type="radio"]').filter(':checked').val()
        // switcher forms keep their donation type in radio buttons, so we need to do things slightly
        // differently for each scenario.
        if (isSwitcherForm()) {
            // once the user selects the annual checkbox, it remains checked even if they switch to monthly.
            // thus we should also check that the monthly radio button is not selected, in case the user
            // switches the donation frequency after checking the annual box.
            if (annualCheckbox && annualCheckbox.checked && donationTypeRadio !== MONTHLY_FREQUENCY) {
              frequency = ANNUAL_FREQUENCY
            } else {
              frequency = donationTypeRadio
            }
        } else {
            // Use the monthly hidden field to get the type of donation form
            frequency = $('input[type="hidden"][name="submitted[donation][recurs_monthly]"]').val();
        }

        if (frequency == MONTHLY_FREQUENCY) {
            return 'monthly';
        } else if (frequency == ANNUAL_FREQUENCY) {
            return 'annual';
        } else {
            return 'one-time';
        }
      }

      function updateButtonText(donationAmount, type) {
        // If the processing fee exists, use Springboard's donation amount (the value inside of $('.processing-fee-message'))
        // as the source of truth for the donation amount. remove all non-numeric characters, preserve float numbers
        if ($('.processing-fee-message').length) {
          donationAmount = $('.processing-fee-message').html().replace(/[^\d.-]/g, '');
          donationAmount = parseFloat(donationAmount);
        }

        if (donationAmount != null && type != null) {
          var donationAmountDisplay = (donationAmount % 1 === 0) ? donationAmount : donationAmount.toFixed(2);
          donationAmountDisplay = donationAmountDisplay.toLocaleString(); // add commas
          switch (type) {
              case "monthly":
                  var buttonText = "Donate $" + donationAmountDisplay + " Monthly";
                  break;
              case "annual":
                  var buttonText = "Donate $" + donationAmountDisplay + " Annually";
                  break;
              case "one-time":
              default:
                  var buttonText = "Donate $" + donationAmountDisplay;
          }
          $('.progress-wrap .complete').html(buttonText);
        }
      }

      window.addEventListener('load', function () {
        const annualCheckbox = $('#edit-submitted-donation-annual-opt-in-1')[0]
        if (annualCheckbox) {
          annualCheckbox.addEventListener('change', function() {
            const donationType = getDonationType()
            updateButtonText(getDonationAmount(), donationType)
            addReminderMessage(donationType)
          })
        }
      });

      // When the gift string Ajax returns after we've already skipped to the payment method step
      // we may have the wrong amount in the donate button. Update it now to reflect the new amounts.
      updateButtonText(getDonationAmount(), getDonationType());

      // Toggles the form position on secondary advocacy forms
      // with the 'Advocacy Social Share Block' markup component
      // This function watches for the change event delivered
      // mediaquery object defined in the 'move-advocacy-donation-form'
      function toggleFormPosition(mediaQueryEvt) {
        // If window width >= 1000px
        // This listener is called everytime the window width changes
        if (mediaQueryEvt.matches && document.querySelector('.webform-client-form') && (!$('.webform-client-form').hasClass('pinned-donation-form'))) {
          // pins donation form to the start of the main content section, in the DOM
          $('.webform-client-form').addClass('pinned-donation-form');
          $('#main-content').prepend($('.webform-client-form'))

          // align the top of the form
          // to the bottom of the title (including its padding).
          // so that the form is positioned to begin within
          // the social share yellow block
          const titleBottomPosition = document.querySelector('.title').getBoundingClientRect().bottom
          const formTopPosition = document.querySelector('.webform-client-form').getBoundingClientRect().top
          // the additional 12 is a UX-requested constant for optimal positioning
          const calculatedFormPosition = titleBottomPosition - formTopPosition - 12
          const formAlignment = {
            top: `${calculatedFormPosition}px`,
            marginBottom: `${calculatedFormPosition}px`,
            position: "relative"
          };
          $('.webform-client-form').css(formAlignment);
        } else if ($('.webform-client-form').hasClass('pinned-donation-form')) {
          // detach pinned donation form on mobile for browser resize
          $('.webform-client-form.pinned-donation-form').appendTo($('.node-donation-form .right-content'));
          $('.webform-client-form').removeClass('pinned-donation-form');

          // reset any previously-modified form styling for mobile
          const formAlignment = {
            top: "0",
            marginBottom: "0"
          };
          $('.webform-client-form').css(formAlignment);
        }
      }

      /**
       * Step processing.
       */

      // Go to a different step.
      function goTo(direction) {
        // perform fieldset validation
        if (direction == '+') {
          var isValid = validateStep(currentStep);
          if (!isValid) return;
        }

        // Determine the new step according to direction.
        var previousStep = currentStep;
        if (direction == '+') {
          if (currentStep + 1 <= 3) {
            currentStep = currentStep + 1;
          }
        } else {
          if (currentStep - 1 >= 0) {
            currentStep = currentStep - 1;
          }
        }

        $(document).trigger('step_updated', [ currentStep ] )

        // Remove the step errors div.
        $(stepFieldsetClass + '.step-' + currentStep).find('.step-errors').remove();
        // Validation and amount display.
        processStep(currentStep);
        updateStepDisplay(previousStep, currentStep);
        scrollToFormTop();
        $('.step-header.step-' + currentStep).focus();
        return false;
      }

      Drupal.goToFirstStep = function () {
        while (currentStep > 0) {
            goTo('-');
        }
        reInitializeApplePay();
      }

      function scrollToFormTop() {
        $('html, body').animate({
          scrollTop: $('.webform-client-form').offset().top
        }, 500);
      }

      // Step-based validation and step reminder-display.
      function processStep(nextStep) {
        if (nextStep == 1) {
          var donationAmount = getDonationAmount();
          if (donationAmount) {
            var type = getDonationType();
            addReminderMessage(type);
            updateButtonText(donationAmount, type);
          }
        }
      }

      function updateStepDisplay(previousStep, step) {
        // When showing the final donation step, we need to decide if we should be showing the
        // credit card fields or the bank account (eft) fields. That happens below.
        if (step === 3) {
          const currentlySelectedPaymentMethod = jQuery('.webform-client-form').find("input[name='submitted[payment_information][payment_method]']:checked").val();
          if (currentlySelectedPaymentMethod === 'credit' && $('.webform-client-form .pay-with-bank').length > 0) {
            Drupal.behaviors.DonationMultistepHidePaymentFields('bank');
          } else if (currentlySelectedPaymentMethod === 'bank account' && $('.webform-client-form .pay-with-cc').length > 0) {
            Drupal.behaviors.DonationMultistepHidePaymentFields('credit');
          }
        }

        // Display the new active fieldset.
        $(stepFieldsetClass + '.active').removeClass('active');
        $(stepFieldsetClass + '.step-' + step).addClass('active');

        $('#main-content, .donation-form-container').find('.step-header.step-' + previousStep).removeClass('active');
        $('#main-content, .donation-form-container').find('.step-header.step-' + step).addClass('active');

        var formStep = step + 1;

        // Change the current step display.
        $('.donation-steps .step').removeClass('active');
        $('.donation-steps .step-' + step).addClass('active');
        // Change accessiblie aria tag indicator
        var stepHeader = $('.step-header.step-'+ formStep).html();
        $('.donation-steps .breadcrumb-nav').attr('aria-label', stepHeader + ', step ' + formStep + ' of 4');

        // Add info about current step to the <body> tag.
        $('body').removeClass('step-' + previousStep + '-active');
        $('body').addClass('step-' + step + '-active');

        currentStep = step;
      }

      // Process and submit donation form.
      function processDonation(){
        // Validate first.
        if ($('.webform-client-form').valid() === true) {
          $paymentFieldsProgress = $paymentFields.find('.progress-buttons .progress-wrap');
          // Hide the Complete and Back buttons, and show a processing button.
          $paymentFieldsProgress.find('.back').hide();
          $paymentFieldsProgress.find('.complete').hide();
          $paymentFieldsProgress.append('<div class="processing">Processing</div>');

          // Click the submit input to trigger submit.
          $('.webform-client-form .form-actions input').click();
        }
      }

      // Validate fields for the current step.
      function validateStep(step) {
        // assume the first tab selector
        var stepSelector = '.fieldset-step.step-0';
        // First-step specific validation
        if (step == 0) {

        // Check if switcher form
        if ($(DONATION_TYPE_QUERY).length) {
           // Get field validation selector from selected switcher frequency
            var stepSelector = null;
            var otherField = null;
            if ( isOneTimeDonationRadioButtonSelected() ) {
              stepSelector = '#webform-component-donation--amount';
            } else if ( isAnnualDonationRadioButtonSelected() ) {
              stepSelector = '.form-item-frequencies-sb-fs-annually-amount';
            } else if ( isMonthlyDonationRadioButtonSelected() ) {
              stepSelector = '#webform-component-donation--recurring-amount';
            }
            if (isOneTimeDonationRadioButtonSelected() ) {
              otherField =  '#webform-component-donation--other-amount input';
            } else if (isMonthlyDonationRadioButtonSelected() || isAnnualDonationRadioButtonSelected()) {
              otherField = '#webform-component-donation--recurring-other-amount input';
            }
        } else {
            // All non-switcher forms monthly only or one-time only just use the default donation--amount componenet for amounts
            var stepSelector = '#webform-component-donation--amount';
            var otherField =  '#webform-component-donation--other-amount input';
          }
          var value = $(stepSelector + ' input[type="radio"]:checked').val();
          // if the value is 0 or undefined
          if (typeof value == 'undefined' || value.length <= 0) {
            // specify validation error text
            setStepError(stepSelector,'<ul><li><a href="' + stepSelector + '" aria-label="A donation amount is required "><span>Amount:</span> Please enter a donation amount.</a></li></ul>');
            // focus the error alert box for accessiblity - screen readers
            $('.step-errors .messages.error').focus();
            // return false
            return false;
          } else if (value == 'other') {
            var otherVal = $(otherField).val();
            // if the other amount is 0 or undefined
            if (typeof otherVal == 'undefined' || otherVal.length <= 0) {
              // request that the user specify an amount
              setStepError(stepSelector,'<ul><li><a href="' + stepSelector + '" aria-label="A donation amount is required "><span>Amount:</span> Please enter a donation amount.</a></li></ul>');
              // focus the alert box for accessibility - screen readers
              $('.step-errors .messages.error').focus();
              // return false
              return false;
            } else if (otherVal < 5) {
              setStepError(stepSelector,'<ul><li><a href="' + stepSelector + '" aria-label="The minimum donation  amount is $5.00"><span>Other Amount:</span> Please enter a donation amount greater than $5.00</a></li></ul>');
              // focus the alert box for accessibility - screen readers
              $('.step-errors .messages.error').focus();
              // return false
              return false;
            } else if (!$.isNumeric(otherVal)) {
              setStepError(stepSelector,'<ul><li><a href="' + stepSelector + '" aria-label="Please enter a valid numerical donation amount."><span>Other Amount:</span> Please enter a valid numerical amount</a></li></ul>');
              // focus the alert box for accessibility - screen readers
              $('.step-errors .messages.error').focus();
              // return false
              return false;
            }
          }
        }

        // Second-step specific validation.
        else if (step == 1) {
          stepSelector = '.fieldset-step.step-1';
        }

        // Third-step specific validation.
        else if (step == 2) {
          stepSelector = '.fieldset-step.step-2';
        }

        // Fourth-step specific validation.
        else if (step == 3) {
          stepSelector = '.fieldset-step.step-3';

          // Get the payment selection.
          var paymentMethod = $(stepSelector + ' .fundraiser-payment-methods input[type="radio"]:checked').val();

          // If there is only one payment method, it will be a hidden input.
          if (typeof paymentMethod == 'undefined') {
            paymentMethod = $(stepSelector).find('input[name*="payment_method"]:checked').val();
            if (typeof paymentMethod == 'undefined') {
              paymentMethod = $(stepSelector).find('input[name*="payment_method"]').val();
            }
          }

          // Only check for errors if the payment method is credit.
          if (paymentMethod == 'credit') {
            // Credit card and cvv selectors.
            $(stepSelector).find('.form-item-submitted-payment-information-payment-fields-credit-card-number input').blur();
            $(stepSelector).find('.form-item-submitted-payment-information-payment-fields-credit-card-cvv input').blur();
            // Check for errors.
            var errors = checkForErrors($(stepSelector));
            if (errors.length > 0) {
              // set step errors
              setStepError(stepSelector, errors);
              // return false
              return false;
            }
          }

          // Check for errors if bank account is present
          if (paymentMethod == 'bank account') {
            $(stepSelector).find('.account-routingNum input').blur();
            $(stepSelector).find('.account-accNum input').blur();
            $(stepSelector).find('.account-accType input').blur();

            // Check for errors.
            var errors = checkForErrors($(stepSelector));
            if (errors.length > 0) {
              // set step errors
              setStepError(stepSelector, errors);
              return false;
            }
          }
          // if there is no defined payment method at this point we will let
          // Springboard & Webform's validation handle it but log a message
          // if (typeof paymentMethod == 'undefined') {
          //   console.log('Error: payment method undefined');
          // }
        }

        // all tabs except for the last: check for jQuery validation errors present on the fieldset selector
        if (step != 3) {
          var errors = checkForErrors($(stepSelector));
          if (errors.length > 0) {
            // set step errors
            setStepError(stepSelector, errors);
            // return false
            return false;
          }
        }

        // Default: clear out the visual errors if they have been corrected.
        $(stepSelector + ' .step-errors').remove();
        // Return true if no step-specific or general required-field errors are found.
        return true;
      }

      /*
       * Use JS to check for a disabled input field, it's the most consistent for our use case
       * Supports togglePaymentField disabled fields
       */
      function inputEnabled(elementID) {
        return (document.getElementById(elementID).getAttribute("disabled") == null)
      }

      /*
       * Step validation.
       *
       * check for errors in each fieldset
       * the approach was to check for the label.error since we are already doing validation with jQuery validate
       * if .error is not found, we need to check if the input is required and force the user to validate that input before progressing
       * */
      function checkForErrors(sel) {
        var hasError = false;
        var errors = '';
        // Find hidden conditional fields.
        hiddenConditionals = sel.find('.form-item').filter('.conditional:hidden');

        // Validate form items, excluding hidden conditional and disabled fields.
        sel.find('.form-item').not(hiddenConditionals).each(function(){
          // Preserve reference to the .form-item elements.
          var formItemEle = $(this);

          // Check whether .error class exists; if not, jQuery has not yet validated/invalidated the input element.
          if (formItemEle.find('label.error')[0]) {
            if (!formItemEle.find('label.error.valid')[0]) {
              // Set error to true; set label error to be displayed.
              formItemEle.addClass('error');
              formItemEle.find('label.error').attr('aria-live="polite"');
              // First select option or the .element-label first-child.
              if (formItemEle.find('label.error').siblings('select')[0] || !formItemEle.find('.text-input-label').length) {
                var inputLabel = formItemEle.find('label:first').text().replace(/[.*]+/g,' ').trim();
              }
              else {
                var inputLabel = formItemEle.find('.text-input-label').text().replace(/[.*]+/g,' ').trim();
              }
              if (formItemEle.hasClass('error') && formItemEle.is('div')) {
                elemID = formItemEle.find('input:first').attr('id');
              } else  if (formItemEle.is('input')){
                elemID = formItemEle.attr('id');
              }  else {
                elemID = formItemEle.find('.webform-component-fieldset.active input:first').attr('id');
              }
              if (inputEnabled(elemID)) {
                hasError = true;
                errors += '<li><a href="#' +  elemID + '"><span>' + inputLabel + '</span>: ' + formItemEle.find('label.error').text().replace(/[.*]+/g,' ').trim() + '</a></li>';
              }
            }
            // If jQuery has not validated this field, validate it.
          } else {
            if (formItemEle.find('input.required')[0] || formItemEle.find('select.required')) {
              // Return before validation if the text element is the other-amount on step one.
              if (formItemEle.find('input.required[name*="other_amount"]')[0]) return;

              // Get input label.
              if (formItemEle.find('label.error').siblings('select')[0] || !formItemEle.find('.text-input-label').length) {
                var inputLabel = formItemEle.find('label:first').text().replace(/[.*]+/g,' ').trim();
              }
              else {
                var inputLabel = formItemEle.find('.text-input-label').text().replace(/[.*]+/g,' ').trim();
              }

              // If not a text element, then validate as a select element; checkboxes have multiple or no values.
              if (formItemEle.find('input.required.form-text')[0] && inputEnabled(formItemEle.find('input.required.form-text').attr('id')) ) {
                var selectedValue = formItemEle.find('input.required.form-text').val();
                if (!selectedValue.length) {
                  // Set error to true; set label error to be displayed.
                  hasError = true;
                  formItemEle.addClass('error');
                  errors += '<li><a href="#' + formItemEle.attr('id') + '"><span>' + inputLabel + '</span>: This field is required.</a></li>';
                }
              } else if (formItemEle.find('select.required')[0]) {
                var selectedValue = formItemEle.find('select.required option:selected').val();
                if (!selectedValue.length > 0) {
                  // Set error to true; set label error to be displayed.
                  hasError = true;
                  formItemEle.addClass('error');
                  errors += '<li><a href="#' + formItemEle.attr('id') + '"><span>' + inputLabel + '</span>: Please select a value.</a></li>';
                }
              }
            }
          }
        });
        if (hasError == true) {
          // Replace label for minimum-amount label.
          errors = errors.replace('<span aria-label="Other Minimum payment $5.00">Other Minimum payment $5.00</span>: ', '');
          return '<ul>' + errors + '</ul>';
        }
        return '';
      }

      // Set errors for a given step.
      function setStepError(stepSelector, errors) {
        // Determine error location.
        var messagePlacement = '';
        if (stepSelector.indexOf('amount') > -1) {
          messagePlacement = '.fieldset-step.step-0';
        }
        else {
          messagePlacement = stepSelector;
        }
        // Add errors.
        if ($(messagePlacement).find(' .step-errors').length > 0) {
          $(messagePlacement).find('.step-errors').html(errors);
        } else {
          $(messagePlacement).find('.fieldset-wrapper').eq(0).prepend('<div class="mobile-message-wrapper"><div class="step-errors messages error"></div></div>');
          $(messagePlacement).find('.step-errors').html(errors);
        }
        $(messagePlacement).find('.step-errors').prepend('<div class="error-intro">Please check the following fields</div>');
        $(stepSelector).find('.form-item.control-group.error:first input').focus();
        // TODO: do we want it ^this way, or should focus go to the dialog sumarizing the errors?
        // $(messagePlacement).find('.step-errors').focus();

        // scrollToFormTop();
      }

      // updates the donation type heading under the step number
      function addReminderMessage(type) {
        var reminderMessage = reminderText.replace('[type]',type);
        // Step 1, 2, and 3 share the same message.
        if ($('.fieldset-step.step-1 .reminder-display').length > 0) {
          $('.fieldset-step.step-1').find('.reminder-display').html(reminderMessage);
          $('.fieldset-step.step-2').find('.reminder-display').html(reminderMessage);
          $('.fieldset-step.step-3').find('.reminder-display').html(reminderMessage);
        } else {
          var reminderMessageHTML = '<div class="reminder-display">' + reminderMessage + '</div>';
          $('.fieldset-step.step-1 .stepContentWrapper').prepend(reminderMessageHTML);
          $('.fieldset-step.step-2 .stepContentWrapper').prepend(reminderMessageHTML);
          $('.fieldset-step.step-3 .stepContentWrapper').prepend(reminderMessageHTML);
        }
      }

      function paymentMethodButtonEvent(e) {
        // We want to fire this event any time any of the payment method
        // buttons (e.g. credit card, paypal) is clicked. We put the event listener
        // on the wrapping div, which serves as the button. However, that causes the
        // event to fire twice: 1) for the input element, and 2) for the label. To
        // prevent running this code twice, we exit early for the input event.
        if (e.target.matches("input[name='submitted[extra_fields][payment_options]']")) return;
        // apple pay button is inserted as a submit input type in the DOM, not a radio button
        let applePayButton = e.currentTarget.querySelector('input.apple-pay-button');
        if (applePayButton) {
          $(applePayButton).trigger('click');
          return;
        }
        $(e.currentTarget).children(':radio').click();
        // for payment methods that should move to the next step of the form
        if (!e.currentTarget.classList.contains('pay-with-paypal')) {
          goTo('+');
        }
      }

      function addDafLink() {
        // #include-daf-link is a custom markup element created by admins
        // to include the DAF link and tooltip on forms
        //
        // See instructions at `docs/daf.md`
        if ($('#include-daf-link').length) {
          // Tooltip for DAF link and EFT buttons
          const infoTooltipHTML = (type) => {
            return (
              '<span class="info-tooltip-trigger" tabindex="0" data-type="' +
              type +
              '">' +
              '<svg aria-describedby="payment-info-'+ (type === 'DAF' ? 'daf' : 'bank')+ '" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<circle cx="7.5" cy="7.5" r="7.5" fill="#EFECEA"/>\n' +
              '<path d="M6.345 4.564V2.666H8.516V4.564H6.345ZM6.371 12V5.5H8.464V12H6.371Z" fill="#231F20"/>\n' +
              '</svg>' +
              (type === 'DAF'
                ? '<span class="info-tooltip-content info-daf" role="tooltip" id="payment-info-daf">If you have a donor-advised fund (DAF), you can make tax-deductible donations and direct grants to the ACLU.</span>'
                : '<span class="info-tooltip-content info-eft" role="tooltip" id="payment-info-bank">US Bank Accounts Only</span>') +
              '</span>'
            );
          };

          $('#webform-component-extra-fields--payment-options').append('<a href="https://www.aclu.org/support-aclu-foundation-through-your-donor-advised-fund" class="pay-with-daf pay-with-other"><div id="daf-link" role="link">Donor Advised Fund' + infoTooltipHTML('DAF') + '</div></a>');

          const eftButton = $('.pay-with-bank');
          const eftButtonLabel = document.querySelector('.pay-with-bank > label');
          if (eftButtonLabel) {
            eftButtonLabel.insertAdjacentHTML('beforeend', infoTooltipHTML('EFT'));
            eftButton.addClass('pay-with-bank-tooltip');
          }

          // needed to set special form styling so that the tooltips aren't
          // hidden on switcher forms
          const donationForm = $('.node-type-donation-form .multistep-donation.default-form-wrapper #webform-component-donation');
          if (donationForm) {
            donationForm.addClass('daf-enabled');
          }

          setPaymentTooltipEvents();
        }
      }

      function paymentMethodTooltipEvent(show, event) {
        if (show) {
          $(event.target).closest('.info-tooltip-trigger').addClass('info-tooltip-showing');
        } else {
          $(event.target).closest('.info-tooltip-trigger').removeClass('info-tooltip-showing');
        }
      }

      function hideVisiblePaymentTooltips() {
        const visibleTooltips = $('.info-tooltip-showing');
        visibleTooltips && visibleTooltips.length > 0 &&
          visibleTooltips.each(function() {
            $(this).removeClass('info-tooltip-showing');
          });
      }

      function setPaymentTooltipEvents() {
        const infoTooltips = $('.info-tooltip-trigger');
        infoTooltips && infoTooltips.each(function() {
          // watches for info icon clicks on touch devices
          if (window.matchMedia('(pointer: coarse)').matches) {
            $(this).click(function(e){
              // On mobile, clicks to the tooltip trigger clicks to the button/link.
              // We only want the tooltip to open, and NOT the button/link click behavior
              // to occur when the user clicks on the info icon.
              e.preventDefault();
              e.stopPropagation();
              paymentMethodTooltipEvent(true, e);
            });

          } else {
            // Required JavaScript tooltip feature:
            // "The tooltip is displayed and disappears via keyboard focus
            // and removal of focus and by the mouse events - mousing over
            // and mousing out."
            // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tooltip_role
            $(this).mouseover(function(e) {
              paymentMethodTooltipEvent(true, e);
            });
            $(this).on( "focus", function(e) {
              paymentMethodTooltipEvent(true, e);
            });
            $(this).mouseleave(function(e) {
              paymentMethodTooltipEvent(false, e);
            });
            $(this).on( "focusout", function(e) {
              paymentMethodTooltipEvent(false, e);
            });
          }
        });

        // closes out the tooltips when the user clicks somewhere
        // aside from the tooltips on a touch device
        $(document).click(function() {
          hideVisiblePaymentTooltips();
        });

        // Required JavaScript tooltip feature is that:
        // "The tooltip can be hidden with the Escape key"
        // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tooltip_role
        $(document).keyup(function(e){
          if (e.key === "Escape" ) {
            hideVisiblePaymentTooltips();
          }
        });

        // Generally, links should not open on 'space bar' key press.
        // Because we are styling our link to look like a button,
        // users will expect button key presses to work. So, we are
        // supporting space bar key presses for this case.
        $('a.pay-with-daf.pay-with-other').keydown(function(e){
          if (e.keyCode == 32 ) {
            e.preventDefault();
            e.target.click();
          }
        });
      }

      function setDefaultFormFieldEvents() {
        // Setup up payment radio button and default webform inputs click and keydown handlers.
        // The custom next and previous button events are defined where the buttons are created
        $('form.webform-client-form', context).once('set-webform-click-events', function() {
          $('.form-item-submitted-extra-fields-payment-options', context).click(function(e){
            paymentMethodButtonEvent(e);
            return false;
          });
          $(PAYMENT_TYPES_QUERY, context).keyup(function(e){
            if (e.key === "Enter" ) {
              // 'Enter' key logic is handled in the keydown listener
              e.preventDefault();
              return false;
            }
          });
          $(PAYMENT_TYPES_QUERY, context).keydown(function(e){
            // [payment method event anchor]
            //
            // The payment options are radio buttons, but we have css to make them look like buttons.
            // This code makes the parent div of each radio option function like a button.
            //
            // Tapping the spacebar supports voiceover key navigation.
            // We only want to override functionality for the following keys. Tab and other
            // keys should work as expected.
            if (e.key === " " || e.key === "Enter" || e.key === "Spacebar" ) {
              paymentMethodButtonEvent(e);
              return false;
            }
          });
          // Add listener to applicable input fields to prevent the the enter key from
          // submitting the form before it reaches the final step of the donation.
          //
          // * Excludes the state field, that keydown event is handled separately since it
          //   can dynamically change. Search `[state event anchor]` in this file to view
          //   that code.
          // * Excludes the payment options fields, those events are handled separately since
          //   there are multiple events that can occur on the various elements that make up
          //   the payment method buttons. Search `[payment method event anchor]` in this file
          //   to view that code.
          $(
            'form.webform-client-form input:not(":button"), .webform-client-form select',
            context
          )
            .not('[name="submitted[billing_information][state]"]')
            .not('[name="submitted[extra_fields][payment_options]"]')
            .not(".form-item-submitted-extra-fields-payment-options")
            .not("input#edit-submit")
            .keydown(webformKeyDown);
        });
      } // end webform click and keydown event definitions

      function webformKeyDown(e) {
      if (e.key == 'Enter' || e.code == 'Enter') {
          e.preventDefault();
          // A non progress button field has been selected go to next step
          $('.webform-component-fieldset.active .progress-buttons .progress-wrap button:first').trigger('click');
          return false;
        }
      }


      function setProgressClickEvents() {
        $('form.webform-client-form', context).once('set-progress-buttons-events', function() {
          // Add event handlers to custom progress buttons we want to have tne Enter key behave like a mouse click
          // for better keyboard only navigation
          $('.progress-buttons .continue', context).click(function(e){
            e.preventDefault();
            goTo('+');
            return false;
          }).keydown(function(e) {
            if (e.key == 'Enter' || e.code == 'Enter') {
              e.preventDefault();
              $(this).trigger('click');
            }
          });
          $('.progress-buttons .back', context).click(function(e){
            e.preventDefault();
            if (currentStep == 1) {
              reInitializeApplePay();
            }
            goTo('-');
            return false;
          }).keydown(function(e) {
          if (e.key == 'Enter' || e.code == 'Enter') {
              e.preventDefault();
              $(this).trigger('click');
            }
          });
          $('.progress-buttons .complete', context).click(function(e){
            e.preventDefault();
            if (validateStep(3) == false) {
              return;
            };
            processDonation();
          }).keydown(function(e) {
            if (e.key == 'Enter' || e.code == 'Enter') {
              e.preventDefault();
              $(this).trigger('click');
            }
          });
        });
      }

      // hack for bank fields auto added by springboard when checkbox is checked in edit tab - there is no option to make them required fields
      $('#edit-submitted-payment-information-payment-fields-bank-account').once('make-bank-fields-required', function() {
        $('input#edit-submitted-payment-information-payment-fields-bank-account-accnum').addClass('required');
        $('input#edit-submitted-payment-information-payment-fields-bank-account-routingnum').addClass('required');
      });

      $( document ).ready(function() {
        // Set events for default webform events added with form components
        setDefaultFormFieldEvents();
        // [state event anchor]
        // init events here to support ajax call when country is changed
        // for the country select on the billing step window.load isn't called, but document.ready is
        // A new state field is dynamically on country billing select.
        // The state form input type depends on the country selected, for some countries a textfield is dynamically added (try Tunisia)
        // For other countries a select form element is added (try Ukraine) the ID of the state field changes an number is incrementally appended to the id text
        // But the name of the element remains the same.
        var stateFieldId = $('[name="submitted[billing_information][state]"]').attr('id');
        $('[name="submitted[billing_information][state]"]').once('setup-dynamic-state-field-event-'+stateFieldId, function() {
          $('[name="submitted[billing_information][state]"]').keydown(webformKeyDown);
        });

      });

      /**
       * Configure multistep on window load.
       */
      $(window).load(function() {
        // Unhide form and stepper (hidden to prevent flash)
        $(".multistep-donation .webform-client-form, .multistep-donation .donation-steps").css({visibility:'visible'});

        // Set up 'Choose Payment Method' text
        $(DONATION_RECURRING_QUERY, context).once('setup-choose-payment-text-toggle', function() {
          $( this ).change(Drupal.acluDonationFormToggleChooseMethodText);
        });

        // Process query string allow url to set form step
        var queryStrings = Drupal.behaviors.FormJS.getQueryParams(document.location.search);
        var initStep = queryStrings.init_step;
        var optin = queryStrings.optin;
        var callMsgType = queryStrings.call_msg;
        var apiMsgType = queryStrings.api_msg;

        // If optin value has been passed use as toggle to show/hide email optin checkbox
        if (typeof optin != 'undefined') {
          if (optin == -1) {
            $('#webform-component-donation--email-opt-in').hide();
          }
        }

        // Check for default secondary advocacy confirmation message
        if ($('.advocacy-donation-form').length) {
          // National or Affiliate click to call confirmation message
          if (typeof callMsgType !== 'undefined') {
            // If a call message type has been passed use this message as the default
            $('.petition-confirmation-message').html('You will receive a call now. Your action is making a big difference in our fight.');
          } else if (typeof apiMsgType !== 'undefined') {
            // If an api submission that is not a click to call action but an email signup form use this default message
            $('.petition-confirmation-message').html('We just sent you an email confirming that you have signed up to receive email from the American Civil Liberties Union (ACLU).');
          }
        }

        // Move Facebook icon first on confirmation page.
        $('.confirmation .sb_social_button_facebook').parent().prependTo($('.sb_social_toolbox')).addClass('moved-in-js');
        $('.advocacy-donation-form.multistep-donation  #advocacy-social-share-block .sb_social_button_facebook').prependTo($('.sb_social_toolbox')).addClass('moved-in-js');
        $('.confirmation.advocacy-donation-form .donation-landing-section.row1').addClass('has-background-yellow mt-xl');

        // Initialize the step-n-active class on the body
        $('body').addClass('step-0-active');

        // Add a class to the 'Other' radio button
        $('input[type="radio"][name="submitted[donation][amount]"][value="other"]', context).parent().addClass('other-button-wrapper');
        $('input[type="radio"][name="submitted[donation][recurring_amount]"][value="other"]', context).parent().addClass('other-button-wrapper');


        // Rearrange the CC expiration date fields
        $('form.webform-client-form', context).once('rearrange-cc-expiration', function() {
          $('.form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month').prepend($('.form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month > .auto-size > .auto-size:first-child'));
          $('.form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month').prepend($('.form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month .auto-size:first-child label'));
        });

        // Add placeholder to Other Amount
        $('input[name*="other_amount"]').attr('placeholder', '0.00');

        // Use Mutation Observer to catch other input field error messages
        // to append syntactically correct minimum payment message via css after style
        // Select the node that will be observed for mutations
        // One of three error messages appear when the webform-component-donation--other-amount node has the error class
        var targetNodeOther = document.getElementById('webform-component-donation--other-amount');
        var targetNodeRecurring = document.getElementById('webform-component-donation--recurring-other-amount');

        // Options for the observer (which mutations to observe)
        // We only want to check if the error class has been added
        var config = { attributes: true};

        // Callback function to execute when mutations are observed
        var otherAmountCallback = function(mutationRecords, observer) {
          mutationRecords.forEach ( function (mutation) {
            if (mutation.type == 'attributes') {
              if (mutation.attributeName === 'class') {
                // Check the parent for switcher forms
                var parentDiv = '#webform-component-donation--other-amount';
                if (mutation.target.className.indexOf('recurring-other-amount-wrapper') > -1 ) {
                  parentDiv = '#webform-component-donation--recurring-other-amount';
                }
                // class changed, check if it's the one we care about
                if ($(parentDiv + ' .currency-in-input label.error').length && !$(parentDiv + ' .currency-in-input label.error').hasClass('valid')) {
                  var curText = $.trim($(parentDiv + ' .currency-in-input label.error').text());
                  if (curText && curText.lastIndexOf(".") >= 0) {
                    curText = curText.slice(0,-1);
                    $(parentDiv + ' .currency-in-input label.error').text(curText);
                  }
                }
              }
            }
          });
        };

        // Create an observer instance linked to the otherAmountCallback function we defined
        var observer = new MutationObserver(otherAmountCallback);

        // Start observing the target node for configured mutations
        if (targetNodeOther) {
          observer.observe(targetNodeOther, config);
        }
        if (targetNodeRecurring) {
          observer.observe(targetNodeRecurring, config);
        }

        // Adds monthly upsell language to all multistep switcher forms. To suppress this on a per form basis,
        // add a markup form component with <div id="recurring-message-multistep-suppress"></div>
        $(DONATION_TYPE_QUERY, context).once('set-recurring-message-multistep', function() {
          if (($(DONATION_TYPE_QUERY).length) && (!$('#recurring-message-multistep-suppress').length)) {
            var monthlyUpsellText = 'A monthly gift helps us respond to urgent threats and plan ahead.';
            if ($('#recurring-message-multistep-replace').length) {
              monthlyUpsellText = $('#recurring-message-multistep-replace').html();
              $('#recurring-message-multistep-replace').hide();
            }
            $(DONATION_TYPE_QUERY + ' #edit-submitted-donation-recurs-monthly-2 + label').append('<div id="recurring-message-multistep">' + monthlyUpsellText + '</div>');
          }
        });

        /**
         * Initialize steps.
         *
         * Set step status and group step fieldsets.
         */
        // Configure step fieldsets.
        $('form.webform-client-form', context).once('step-progress-buttons', function() {
          $(this).attr('name','webform-donation');

          // On the donation landing screen, move the step bars before the fieldsets
          if (isDonationLandingForm()) {
            $('.donation-steps').insertBefore($('.fieldset-step.step-0'));
          }

          $(stepFieldsetClass, context).each(function (progressStep) {
            step = progressStep;
            if (step == 0) {
              $('#webform-component-extra-fields--payment-options, #webform-component-extra-fields--payment-message').wrapAll('<div class="progress-buttons"><div class="progress-wrap"></div></div>');
              Drupal.acluDonationFormToggleChooseMethodText();
              $(this).addClass('active');
              activeInd = 'active';
            } else if (step == 1 || step == 2) {
              // Add name attribute to buttons using springboard bracket format to avoid triggering paypal JS code processing all inputs in the donation fieldset
              $(this).append('<div class="progress-buttons"><div class="progress-wrap"><button type="button" name="button[progress][next]" class="continue">Next</button><button type="button" form="webform-donation" name="button[progress][previous]" class="back">Previous</button></div></div>');
              activeInd = '';
            } else {
              // Add name attribute to buttons using springboard bracket format to avoid triggering paypal JS code processing all inputs in the donation fieldset
              $(this).append('<div class="progress-buttons"><div class="progress-wrap"><button type="submit" name="button[progress][donate]" class="complete">Donate</button><button type="button" name="button[progress][previous]" class="back">Previous</button></div></div>');
              activeInd = '';
            }
            var stepClass = 'step-' + step;
            var maxSteps = 4;
            var formStep = step + 1;

            $(this).addClass(stepClass);
            var curLegend = $(this).find('legend');
            // Add aria accessibiliy tags since we are hacking the fieldsets and using as form progress indicators
            var legendTextWrapper = $(this).find('legend span.fieldset-legend');
            var screenreaderStepLabel = "Step " + formStep + " of " + maxSteps + ": " + legendTextWrapper.text();
            var headerLabel = legendTextWrapper.text() + "heading";

            // insert the step navigation element
            var stepNavigationElement = $('<legend class="step-header ' +  stepClass + ' ' + activeInd + '" id="' + headerLabel + '" tabindex="-1"><span class="screenreader-only">' + screenreaderStepLabel + '</span><span class="visible-label" form-progress="' + formStep + '" aria-hidden="true">' + legendTextWrapper.text() + '</span></legend>');
            if (isDonationLandingForm()) {
              stepNavigationElement.prependTo($(this));
            } else {
              stepNavigationElement.insertBefore(curLegend.parent());
            }

            curLegend.remove();
            stepClass = '.' + stepClass;
            $(stepClass + ' .fieldset-wrapper, ' + stepClass + ' .form-wrapper, ' + stepClass + ' .progress-buttons').wrapAll('<div class="stepContentWrapper"></div>');
          });

          setProgressClickEvents();

          // Move Apple Pay (e.g. form submit) button above credit card button.
          $('#edit-actions').insertBefore($('.pay-with-cc'));

          addDafLink();
        });

        // Add separate expiration date labels for CC
        var expirationMonthID = "edit-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month";
        var expirationMonthLabel = "<label for='" + expirationMonthID + "'>Expiration month</label>";
        $(expirationMonthLabel).insertBefore('#'+expirationMonthID);

        var expirationYearID = "edit-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-year";
        var expirationYearLabel = "<label for='" + expirationYearID + "'>Expiration year</label>";
        $(expirationYearLabel).insertBefore('#'+expirationYearID);

        // Credit Card Button
        $('.webform-client-form').find('.pay-with-cc').click(function(e){
          var ccRadio = $('.webform-client-form').find("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'credit' });
          // Trigger built in cc radio click to make sure  cc payment method is selected
          jQuery(ccRadio).click();
        });

        // Bank Account Button
        $('.webform-client-form').find('.pay-with-bank').click(function(e){
          var baRadio = $('.webform-client-form').find("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'bank account' });
          // Trigger built in bank account radio click to make sure  bank payment method is selected
          jQuery(baRadio).click();
        });

        // Paypal Button
        var paypalRadio = $('.webform-client-form').find("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'paypal' });
        paypalRadio.click(function(e) {
          if (validateStep(0) == false) {
            return
          };
          Drupal.behaviors.DonationFormJS.acluSubmitPaypal(e);
        });
        $('.webform-client-form').find('.pay-with-paypal').click(function(e){
          var paypalRadio = $('.webform-client-form').find("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'paypal' });
          // Trigger built in paypal radio click to make sure all paypal functionality loaded onclick is triggered
          jQuery(paypalRadio).click();
        });

        // Apple Pay (i.e. form submission) Button -- validate amounts before submitting
        var applePayButton = $('.applepay-available #edit-submit');
        applePayButton.click(function(e) {
          // Only do the Apple Pay validation if we're on the first step of the form.
          if ($('body.step-0-active').length > 0) {
            if (validateStep(0) == false) {
              e.preventDefault();
              return false;
            }
            else {
              $('.step-errors').remove();
              return true;
            }
          }
        });

        // If there was a CC number error, go right to that step
        $('form.webform-client-form', context).once('skip-to-payment-information', function () {
          function getPaymentInfoTypeError(errorMessage) {
            for (type in paymentInfoErrorMessageTypes) {
              for (var i = 0; i < paymentInfoErrorMessageTypes[type].messages.length; i++) {
                if (paymentInfoErrorMessageTypes[type].messages[i].toLowerCase() === errorMessage.toLowerCase()) {
                  return paymentInfoErrorMessageTypes[type];
                }
              }
            }
            return false;
          }
          var skipToPaymentStep = false;
          var errorType = 'CC';
          var paymentInfoErrorMessageTypes = {
            creditCardNumberErrors: {
              field: 'Credit card number',
              errorType: 'CC',
              domSelector: '#edit-submitted-payment-information-payment-fields-credit-card-number',
              messages: [
                'You have entered an invalid credit card number.',
                'We received an error processing your card. Please enter your information again or try a different card.',
                'The credit card number is required.'
              ]
            },
            cVVNumberErrors: {
              field: 'CVV',
              errorType: 'CC',
              domSelector: '#edit-submitted-payment-information-payment-fields-credit-card-cvv',
              messages: [
                'You have entered an invalid CVV number.',
                'The credit card CVV is required.'
              ]
            },
            expirationDateErrors: {
              field: 'Expiration date',
              errorType: 'CC',
              domSelector: '#edit-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month',
              messages: [
                'The credit card you entered has expired.'
              ]
            },
            accountNumberErrors: {
              field: 'Account number',
              errorType: 'EFT',
              domSelector: '#edit-submitted-payment-information-payment-fields-bank-account-accnum',
              messages: [
                'You have entered an invalid account number.'
              ]
            },
            routingNumberErrors: {
              field: 'Routing number',
              errorType: 'EFT',
              domSelector: '#edit-submitted-payment-information-payment-fields-bank-account-routingnum',
              messages: [
                'The routing number should be a 9 digit numeric value.'
              ]
            }
          };
          var errorMessageListHTML = '';

          if ($('.message-wrapper .alert.error ul li').length) {
            // We have a list of errors; only skip to the payment step if we've *only* got
            // payment info errors.
            $('.message-wrapper .alert.error ul li').each(function (index, errorListItem) {
              if (errorListItem.textContent.toLowerCase() === "the litle credit card payment method is configured to use the pre-live environment and will not process live transactions.") {
                // This is a debug environment error message, so we can just ignore it.
                return true;
              }
              var paymentInfoTypeError = getPaymentInfoTypeError(errorListItem.textContent);
              if (!paymentInfoTypeError) {
                // We've found a non-payment-info error, don't skip to the payment
                // info step and stop checking altogether.
                skipToPaymentStep = false;
                return false;
              } else {
                // We've found a payment info error -- skip to payment info step
                // (unless we find a non-payment-info error in the list of errors).
                skipToPaymentStep = true;
                errorType = paymentInfoTypeError.errorType;
                errorMessageListHTML += '<li><a href="' + paymentInfoTypeError.domSelector + '"><strong>' + paymentInfoTypeError.field + ':</strong> ' + errorListItem.textContent + '</a></li>';
              }
            });
          }
          else if ($('.message-wrapper .alert.error').length) {
            // There was only one error message, not a list. If that
            // error was a credit card error, skip to credit card step
            var errorMessageWrapper = document.querySelector('.message-wrapper .alert.error');
            if (errorMessageWrapper.childNodes[4] && errorMessageWrapper.childNodes[4].nodeValue) {
              var paymentTypeError = getPaymentInfoTypeError(errorMessageWrapper.childNodes[4].nodeValue.trim());
              if (paymentTypeError) {
                skipToPaymentStep = true;
                errorType = paymentTypeError.errorType;
                errorMessageListHTML += '<li><a href="' + paymentTypeError.domSelector + '"><strong>' + paymentTypeError.field + ':</strong> ' + errorMessageWrapper.childNodes[4].nodeValue.trim() + '</a></li>';
              }
            }
          }

          if (skipToPaymentStep) {

            // Select the credit card or pay with bank payment method, depending on error type
            const formItemClass = errorType === 'CC' ? '.pay-with-cc' : '.pay-with-bank';
            $('.webform-client-form').find(formItemClass).click();

            // Move to the payment step
            updateStepDisplay(currentStep, 3);
            var type = getDonationType();
            addReminderMessage(type);
            updateButtonText(getDonationAmount(), type);

            // Move the error message to the expected spot.
            $('.message-wrapper .alert.error').remove();
            setStepError(stepFieldsetClass + '.step-' + currentStep, '<ul>' + errorMessageListHTML + '</li>');
          }
          else {
            // If we're not skipping to the final step of the donation form,
            // we need to click the Apple Pay radio button when the form loads
            // so that when the user clicks the 'Submit with Apple Pay' button
            // (which is actually the form submit button) all Apple Pay setup is
            // already done (nonces, etc.).
            $("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'applepay' }).trigger('click');
          }
        });

        // If all required skip-step parameters have been passed, use to set initial step in form
        // check first to make sure the aclu-multistep-skip-step.js module is included in this wrapper
        // supports:
        // initStep = 0 preselect frequency, amount and payment method stay on first step
        // initStep = 1 preslect all required fields on step 1 and skip to second step
        if (Drupal.behaviors.DonationFormValidateSkipStep && Drupal.behaviors.DonationFormValidateSkipStep.attach()) {
          if (initStep == 1) {
            goTo("+")
          }
        }

        // Move secondary advocacy donation webform into pagewrapper placeholder div
        $('#advocacy-social-share-block').once('move-advocacy-donation-form', function() {
          // Repsonsive code for to toggle webform position in markup by browser width
          // using javascript media queries
          // https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries
          // 1000px in chrome had some quirks we want the form to be absolute/pinned
          // Due to the design of the form we need the form to be relative
          // at the media query width of 999px;
          // $(window).width() was not consitent across browsers
          var mediaQuery = window.matchMedia('(min-width: 1000px)');
          toggleFormPosition(mediaQuery);
          // If you need to be kept aware of whether or not the document
          // matches the media query at all times, you can instead watch
          // for the change event to be delivered to the object.
          // We get flickering if we don't
          mediaQuery.addEventListener('change', toggleFormPosition)
        });
      }); // $(window).load() event.

      // If we've got a CAPTCHA, rearrange the HTML and move it within the first step
      $('.captcha.form-wrapper', context).once('move-captcha', function() {
        $('body').addClass('captcha-page');  // for styling
        $('legend', this).remove();

        var newCaptchaHTML = $('<div class="captcha form-wrapper"></div>');
        $('<div class="reminder-display">CAPTCHA</div>').appendTo(newCaptchaHTML);
        $('.fieldset-description', this).appendTo(newCaptchaHTML);
        $('input[type="hidden"]', this).appendTo(newCaptchaHTML);
        $('img', this).appendTo(newCaptchaHTML);
        $('.form-item-captcha-response', this).appendTo(newCaptchaHTML);

        newCaptchaHTML.insertAfter($('#webform-component-donation--email-opt-in'));
        $(this).remove();
      });

      Drupal.behaviors.DonationMultistepHidePaymentFields  = function (disablePaymentType, settings={}) {
        /*
         * Undo required validation on a field.
         * For use when switching between Credit and Bank Account.
         *
         * $element: a jQuery object that points to the outer div of the input field
         */
        var bankPaymentFields =  $("input[id='edit-submitted-payment-information-payment-fields-bank-account-accnum'], input[id='edit-submitted-payment-information-payment-fields-bank-account-routingnum']");
        var bankPaymentLabelsReq = $("label[for='edit-submitted-payment-information-payment-fields-bank-account-accnum'], label[for='edit-submitted-payment-information-payment-fields-bank-account-routingnum'], label[for='edit-submitted-payment-information-payment-fields-bank-account-acctype']").not(":contains('*')");
        var creditCardPaymentFields = $("input[id='edit-submitted-payment-information-payment-fields-credit-card-number'], input[id='edit-submitted-payment-information-payment-fields-credit-card-cvv']");
        var creditCardPaymentLabelsReq = $("label[for='edit-submitted-payment-information-payment-fields-credit-card-number'], label[for='edit-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month'], label[for='edit-submitted-payment-information-payment-fields-credit-card-cvv']").not(":contains('*')");

        // Disable paymentType fields
        if (disablePaymentType == 'bank') {
          //
          // If the bank payment type is unavailable...
          // DO NOT show the bank info payment fields & DO show the credit card payment fields
          // Step 4 title should NOT say 'Bank account' (it should say 'Credit card')
          //
          // Switch field visibility
          $('.form-item-submitted-payment-information-payment-fields-bank').closest('.fieldset-wrapper').hide();
          $('.form-item-submitted-payment-information-payment-fields-credit-card-number').closest('.fieldset-wrapper').show();

          // Update step header
          $('.step-header.step-3').attr('id', 'Credit cardheading');
          $('.step-header.step-3 span.visible-label').html('Credit card');
          $('.step-header.step-3 span.screenreader-only').html('Step 4 of 4: Credit card');

          // Disable bank input fields and reset value if user switches between two payment methods
          bankPaymentFields.attr('disabled', true).val();

          // Enable credit card fields
          creditCardPaymentFields.attr('disabled', false);

          // Reset labels also a hack for SB after form is submitted
          creditCardPaymentLabelsReq.append('<span class="form-required">*</span>');

        } else {
          //
          // If the credit card payment type is unavailable...
          // DO show the bank info payment fields & DO NOT show the credit card payment fields
          // Step 4 title should say 'Bank account' (it should NOT say 'Credit card')
          //
          // Swap field visibility
          $('.form-item-submitted-payment-information-payment-fields-credit-card-number').closest('.fieldset-wrapper').hide();
          $('.form-item-submitted-payment-information-payment-fields-bank').closest('.fieldset-wrapper').show();

          // Update step header
          $('.step-header.step-3').attr('id', 'Bank accountheading');
          $('.step-header.step-3 span.visible-label').html('Bank account');
          $('.step-header.step-3 span.screenreader-only').html('Step 4 of 4: Bank account');

          // Enable bank fields
          bankPaymentFields.attr('disabled', false);

          // hack for when fields are re-added afer form is submitted with invalid account num
          bankPaymentLabelsReq.append('<span class="form-required">*</span>');

          // Disable and clear credit card fields
          creditCardPaymentFields.attr('disabled', true).val();
        }
      }
    }
  }

})(jQuery);
